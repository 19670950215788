import Slider, {Settings} from "react-slick";
import H2TrapeziumBG from "../../../_hoc/H2TrapeziumBG";
import {IWinzoWinnerComponentProps} from '../../../lib/types/components';
import {dynamicCssSize, isiOSDevice} from "../../../lib/utils/constants/PagesConstants";
import CustomImageComponent from '../../common/CustomImage/CustomImageComponent';
import {useEffect} from "react";

const WinzoWinnerComponentV2 = (props: IWinzoWinnerComponentProps) => {

    let {winzoWinnerData} = props;
    let winnerSlider: Slider | null = null
    const sliderSettings = {
        className: "sliderContainerWinZOWinners center",
        centerMode: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerPadding: "0px",
        arrows: false,
        responsive:[
            {
                breakpoint: 767,
                settings: {
                    infinite: false,
                    centerPadding: "20px",
                    slidesToShow: 1
                }
            },
        ]
    }

    useEffect(() => {
        if (winnerSlider) {
            winnerSlider.slickNext()
        }
    }, [])

    return (
        winzoWinnerData && Object.keys(winzoWinnerData).length ?
            <H2TrapeziumBG>
                <style jsx>
                    {`
                      .parentContainer {
                        padding-bottom: ${dynamicCssSize(50, 0)};
                        background-color: #ebebeb;
                        background-image: ${winzoWinnerData.userCardData.length && `url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/winzo_winner_leftV2.webp"), url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/winzo_winner_rightV2.webp")`};
                        background-repeat: no-repeat;
                        background-position: left 75%, right 75%;
                        background-size: 16%, 14%;
                      }

                      .mainContainer {
                        font-family: var(--font-primary);
                        padding: 18px 10px;
                        position: relative;
                      }

                      .upperQuoteImageContainer {
                        position: absolute;
                        width: ${dynamicCssSize(80, 60)};
                        height: ${dynamicCssSize(70, 50)};
                        top: 17%;
                        left: 10%;
                        transform: rotate(180deg);
                      }

                      .lowerQuoteImageContainer {
                        position: absolute;
                        width: ${dynamicCssSize(110, 80)};
                        height: ${dynamicCssSize(100, 70)};
                        top: 93%;
                        left: 85%;
                      }

                      .upperQuoteImage {
                        position: relative;
                        width: 100%;
                        height: 100%;
                      }

                      .lowerQuoteImage {
                        position: relative;
                        width: 100%;
                        height: 100%;
                      }

                      .headingParent {
                        display: flex;
                        justify-content: center;
                      }

                      .heading {
                        font-size: ${dynamicCssSize(60, 16)};
                        text-transform: uppercase;
                        font-weight: 900;
                        font-style: italic;
                        letter-spacing: 2.25px;
                        text-align: center;
                        color: #35255a;
                        max-width: 65vw;
                      }

                      .sliderContainer {
                        margin-top: 30px;
                        height: ${dynamicCssSize(500, 250)};
                        display: flex;
                        align-items: center;
                        padding-bottom:${isiOSDevice?20:0}px;
                      }

                      .slideParentContainer {
                        height: ${dynamicCssSize(500, 350)};
                        display: flex !important;
                        align-items: center;
                        position: relative;
                        z-index: 0;
                      }

                      .slideBorderContainer {
                        //height: 90%;
                        position: relative;
                        border-radius: 21px;
                        padding: 2px;
                        clip-path: polygon(0 0, calc(100% - 35px) 0, 100% 30px, 100% 100%, 0 100%);
                        transition: all 0.7s ease;
                      }

                      .slide {
                        height: ${dynamicCssSize(250, 210)};
                        border-radius: 19px;
                        background: #ffffff;
                        padding: ${dynamicCssSize(30, 18)} ${dynamicCssSize(20, 14)};
                        clip-path: polygon(0 0, calc(100% - 35px) 0, 100% 30px, 100% 100%, 0 100%);
                      }

                      .userDetailsContainer {
                        //height: 80%;
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;
                      }

                      .userImgContainer {
                        width: 30%;
                        height: 100%;
                        position: relative;
                        display: inline-block;
                        border-radius: 12px;
                        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
                      }

                      .userImgContainer img {
                        border-radius: 12px;
                      }

                      .userDetails {
                        width: 70%;
                        height: 40%;
                        padding: 15px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                      }

                      .userName {
                        font-size: 17px;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: -0.17px;
                        text-align: left;
                      }

                      .amountWon {
                        background: linear-gradient(85deg, rgba(229, 175, 70, 1) 0%, rgba(250, 132, 21, 1) 100%);
                        font-size: ${dynamicCssSize(22, 11)};
                        font-weight: 900;
                        font-style: italic;
                        line-height: normal;
                        letter-spacing: -0.28px;
                        -webkit-background-clip: text;
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                      }

                      .userDescription {
                        display: flex;
                        width: 70%;
                        height: 60%;
                        padding: 0px 15px;
                        justify-content: center;
                        overflow-y: scroll;
                        font-size: ${dynamicCssSize(14, 11)};
                        letter-spacing: -0.13px;
                        opacity: 0.7;
                        color: rgba(0, 0, 0, 0.87);
                        line-height: 16px;
                      }

                      @media screen and (max-width: 1200px) {
                        .parentContainer {
                          background-image: none;
                        }

                        .userName {
                          font-size: 12px;
                        }

                        .amountWon {
                          font-size: 14px;
                        }

                        .userDescription {
                          line-height: 17px;
                        }
                      }

                      @media screen and (max-width: 576px) {
                        .parentContainer {
                          padding-bottom: 0;
                        }

                        .mainContainer {
                          padding: 10px 0px 0px;
                        }

                        .upperQuoteImageContainer {
                          display: none;
                        }

                        .lowerQuoteImageContainer {
                          display: none;
                        }

                        .heading {
                          line-height: 0.67;
                          letter-spacing: 1px;
                        }

                        .userDetailsContainer {
                          flex-direction: row;
                        }

                        .userImgContainer {
                          width: 25%;
                          height: 50%;
                          clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);
                        }

                        .userDetails {
                          width: 75%;
                          height: 50%;
                        }

                        .userDescription {
                          width: 100%;
                          height: 50%;
                          padding: 10px 0px;
                          line-height: normal;
                          font-size: ${dynamicCssSize(20, 13)};
                        }

                        //.slideParentContainer{
                        //  height: 280px;
                        //}
                        .sliderContainer {
                          margin-top: 0px;
                        }

                        .slideBorderContainer {
                          //height: 100%;
                          clip-path: polygon(0 0, calc(100% - 30px) 0, 100% 25px, 100% 100%, 0 100%);
                        }

                        .slide {
                          //height: 100%;
                          clip-path: polygon(0 0, calc(100% - 30px) 0, 100% 25px, 100% 100%, 0 100%);
                        }

                        .slideParentContainer {
                          width: 100%;
                          padding: 10px;
                        }

                        .userName {
                          font-size: 14px;
                        }

                        .amountWon {
                          font-size: 16px;
                        }
                      }
                    `}
                </style>
                <div className={"container-fluid parentContainer"}>
                    <div className={"container mainContainer"} id="id_winzo_winners">
                        {winzoWinnerData.userCardData.length ? <div className={"upperQuoteImageContainer"}>
                            <div className={"upperQuoteImage"}>
                                <CustomImageComponent
                                    layout={'fill'}
                                    objectFit={'contain'}
                                    src={'https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/quote_imageV2.svg'}
                                    alt={"quote image"}
                                />
                            </div>
                        </div> : <></>}
                        {winzoWinnerData.userCardData.length ? <div className={"lowerQuoteImageContainer"}>
                            <div className={"lowerQuoteImage"}>
                                <CustomImageComponent
                                    layout={'fill'}
                                    objectFit={'contain'}
                                    src={'https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/quote_imageV2.svg'}
                                    alt={"quote image"}
                                />
                            </div>
                        </div> : <></>}

                        <div className={"headingParent"}>
                            <h2 className={"heading"}>{winzoWinnerData.heading}</h2>
                        </div>


                        <div className={"sliderContainer"}>
                            <div style={{width: "100%"}}>
                                <Slider ref={slider => (winnerSlider = slider)} {...sliderSettings}>
                                    {
                                        winzoWinnerData.userCardData.map((item, index) => {
                                            return (
                                                <div className={"slideParentContainer"}
                                                     key={"user_card_data_" + index}>
                                                    <div className={"slideBorderContainer"}>
                                                        <div className={"userDetailsContainer slide"}>
                                                            <div className={"userImgContainer"}>
                                                                <CustomImageComponent
                                                                    layout={'fill'}
                                                                    objectFit={'cover'}
                                                                    src={item.userImg}
                                                                    alt={"winzo-winners-user-image"}
                                                                    className={"winzoWinnerImage"}
                                                                />
                                                            </div>
                                                            <div className={"userDetails"}>
                                                                <div className={"userName"}>
                                                                    {item.userName}
                                                                </div>
                                                                <div className={"amountWon"}>
                                                                    {item.priceWon}
                                                                </div>

                                                            </div>
                                                            <div className={"userDescription"}>
                                                                <div>
                                                                    {item.desc}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>

            </H2TrapeziumBG> : <></>
    )
}

export default WinzoWinnerComponentV2;